<template>
  <div class="orderTest-container">
    <div class="top">
      <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="-"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
      <el-button
        style="margin-left: 10px"
        type="primary"
        @click="
          () => {
            form.pageSize = 10
            form.pageNo = 1
            fetchData()
          }
        "
      >
        查询
      </el-button>
      <el-button @click="handleExport">导 出</el-button>
    </div>
    <div class="wrap">
      <div class="left">
        <el-table
          v-loading="loading1"
          stripe
          height="550"
          :data="leftList"
          border
          row-key="id"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :indent="34"
          default-expand-all
          highlight-current-row
          @current-change="handleClickL"
        >
          <el-table-column
            prop="name"
            label="科目列表"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div class="right">
        <el-table v-loading="loading2" stripe :data="rightList" height="550">
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          >
            <template #default="{ row }">
              <div v-if="item.label == '方向'">
                <span v-if="row.inout_class == 1">贷</span>
                <span v-else>借</span>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { leftTableData, rightTableData } from '@/api/financial'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: 'KeMuMingXi',
    components: {},
    data() {
      return {
        leftList: [],
        loading1: false,
        year: '',
        time: [],
        total: 0,
        form: {
          start_time: '',
          end_time: '',
          pageSize: 10,
          pageNo: 1,
          subject_id: '',
        },
        checkList: [
          '日期',
          '凭证字号',
          '单据编号',
          '凭证制单人',
          '摘要',
          '借方',
          '贷方',
          '方向',
          '余额',
        ],
        columns: [
          {
            order: 1,
            label: '日期',
            prop: 'create_at',
            width: '',
          },
          {
            order: 2,
            label: '凭证字号',
            prop: 'code',
            width: '',
          },
          {
            order: 3,
            label: '单据编号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 4,
            label: '凭证制单人',
            prop: 'add_user',
            width: '',
          },
          {
            order: 5,
            label: '摘要',
            prop: 'note',
            width: '',
          },
          {
            order: 6,
            label: '借方',
            prop: 'type_add',
            width: '',
          },
          {
            order: 7,
            label: '贷方',
            prop: 'type_less',
            width: '',
          },
          {
            order: 8,
            label: '方向',
            prop: '',
            width: '',
          },
          {
            order: 9,
            label: '余额',
            prop: 'end_money',
            width: '',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        rightList: [],
        loading2: false,
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.getLeftTableData()
    },
    methods: {
      async fetchData() {
        this.loading2 = true
        let { data, totalCount } = await rightTableData(this.form)
        this.rightList = data
        this.total = totalCount
        this.loading2 = false
      },
      async getLeftTableData() {
        this.loading1 = true
        let { data } = await leftTableData({ year: this.year, pageSize: -1 })
        console.log('data', data)
        this.leftList = data
        this.loading1 = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 点击科目
      handleClickL(val) {
        console.log(val.id)
        this.form.subject_id = val.id
        this.fetchData()
      },
      // 导出
      handleExport() {
        downloadFile(
          'cashAdmin/subject-detail/list-export',
          '科目明细.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .top {
    margin-bottom: 15px;
  }
  .wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: content;
    .left {
      width: 20%;
      margin-right: 10px;
    }
    .right {
      width: 80%;
    }
  }
</style>
